import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1042.000000 1042.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1042.000000) scale(0.100000,-0.100000)">
          
          <path d="M5152 7482 c-72 -26 -122 -69 -155 -133 -57 -112 -43 -231 39 -319
65 -69 127 -94 225 -88 88 5 147 34 198 95 89 108 79 286 -21 379 -78 74 -190
100 -286 66z"/>
<path d="M4580 6856 c-25 -7 -65 -23 -88 -35 -52 -27 -134 -100 -126 -112 3
-5 118 -97 257 -205 139 -108 261 -204 272 -213 20 -16 20 -16 0 7 -12 15 -15
26 -9 30 42 29 314 246 314 251 0 11 -259 212 -308 239 -88 49 -214 65 -312
38z"/>
<path d="M5836 6426 c-120 -154 -207 -270 -193 -258 l25 24 66 -84 c35 -45 94
-120 129 -165 l64 -83 104 133 c56 72 115 152 131 177 61 99 73 244 29 359
-21 56 -90 155 -119 170 -14 7 -52 -36 -236 -273z"/>
<path d="M3762 6092 c-72 -26 -122 -69 -155 -133 -57 -112 -43 -231 39 -319
65 -69 127 -94 225 -88 88 5 147 34 198 95 89 108 79 286 -21 379 -78 74 -190
100 -286 66z"/>
<path d="M6497 6100 c-58 -15 -89 -34 -136 -85 -56 -61 -74 -106 -74 -185 -1
-162 116 -280 278 -280 160 0 277 116 278 274 1 109 -72 222 -168 262 -45 18
-131 25 -178 14z"/>
<path d="M4375 5714 c-154 -197 -181 -260 -173 -404 4 -65 11 -92 41 -152 20
-40 53 -90 75 -111 l40 -39 209 269 c219 280 242 312 202 276 l-24 -21 -124
159 c-68 87 -128 159 -132 159 -4 0 -56 -61 -114 -136z"/>
<path d="M5513 5423 c9 -11 17 -22 17 -25 0 -3 -72 -61 -159 -129 -87 -68
-157 -127 -155 -131 16 -25 288 -226 340 -250 115 -54 273 -47 379 17 45 27
115 89 115 103 0 8 -502 406 -539 426 -13 8 -13 6 2 -11z"/>
<path d="M5123 4779 c-88 -17 -175 -86 -211 -168 -23 -53 -21 -165 5 -221 47
-103 143 -164 258 -164 81 0 147 29 202 86 77 80 97 216 47 316 -55 111 -181
175 -301 151z"/>
<path d="M6411 3794 c-30 -8 -57 -17 -59 -19 -2 -2 -2 -16 2 -30 6 -22 12 -25
51 -25 l45 0 0 -394 0 -395 58 -21 c169 -65 337 -17 419 119 111 185 25 446
-155 468 -50 6 -55 4 -132 -45 l-80 -51 0 183 c0 178 -1 183 -23 204 -27 25
-51 26 -126 6z m367 -414 c22 -14 42 -39 59 -76 22 -49 25 -66 21 -138 -3 -67
-9 -92 -31 -129 -15 -25 -37 -53 -49 -62 -32 -21 -123 -19 -176 5 l-42 19 2
173 3 173 54 28 c65 32 114 34 159 7z"/>
<path d="M3613 3703 c-16 -3 -23 -11 -23 -28 0 -25 17 -35 61 -35 l29 0 -2
-332 -3 -332 -50 -20 c-27 -10 -51 -20 -53 -21 -1 -1 0 -10 4 -19 5 -14 29
-16 203 -16 206 1 252 6 329 42 189 89 266 358 161 567 -39 77 -118 152 -192
179 -52 20 -76 22 -250 21 -106 -1 -202 -4 -214 -6z m456 -99 c78 -69 121
-177 121 -299 0 -133 -48 -230 -143 -289 -50 -31 -149 -45 -221 -32 l-36 7 2
317 3 317 65 6 c155 14 166 12 209 -27z"/>
<path d="M4832 3673 c3 -25 8 -29 48 -33 l45 -5 0 -330 0 -329 -50 -20 c-27
-11 -51 -21 -53 -22 -1 0 0 -9 4 -18 5 -14 27 -16 171 -16 l164 0 -3 28 c-3
23 -8 27 -38 30 -86 6 -80 -4 -80 152 l0 140 200 0 200 0 0 -139 0 -139 -50
-17 c-37 -12 -50 -22 -50 -36 0 -18 9 -19 166 -19 l165 0 -3 28 c-2 20 -9 28
-28 30 -14 1 -40 5 -57 8 l-33 5 0 327 0 328 53 19 c63 22 59 19 51 39 -5 14
-26 16 -156 16 l-149 0 3 -27 c3 -25 7 -28 46 -31 l42 -3 0 -150 0 -149 -200
0 -200 0 0 144 0 144 50 17 c37 12 50 22 50 36 0 18 -9 19 -156 19 l-155 0 3
-27z"/>
<path d="M5833 3625 c-103 -28 -253 -122 -253 -159 0 -7 13 -25 29 -40 l30
-29 60 46 c94 70 156 91 276 91 120 0 182 -21 276 -91 l60 -46 30 29 c16 15
29 33 29 40 0 38 -151 132 -257 159 -71 19 -211 18 -280 0z m306 -34 c68 -21
204 -106 198 -124 -10 -31 -34 -28 -92 12 -159 112 -381 112 -540 0 -58 -40
-82 -43 -92 -12 -4 12 102 88 154 109 111 47 252 52 372 15z"/>
<path d="M5863 3456 c-54 -17 -122 -57 -150 -88 l-23 -24 35 -34 c26 -25 39
-32 49 -25 67 50 100 67 148 77 75 16 153 -2 213 -48 25 -19 49 -34 53 -34 4
0 23 15 41 34 l32 33 -38 33 c-64 57 -136 84 -233 87 -53 2 -101 -2 -127 -11z
m262 -45 c33 -17 69 -37 79 -45 18 -15 19 -17 2 -35 -17 -19 -18 -19 -59 8
-58 39 -100 51 -172 51 -72 0 -114 -12 -172 -51 -39 -26 -42 -26 -57 -10 -15
16 -14 19 7 38 91 84 250 103 372 44z"/>
<path d="M4386 3285 c-3 -8 2 -28 12 -45 l17 -30 156 0 c174 0 182 3 147 58
l-20 32 -153 0 c-126 0 -154 -3 -159 -15z"/>
<path d="M5924 3289 c-22 -5 -56 -23 -75 -39 l-34 -29 37 -37 36 -37 27 22
c16 11 42 21 60 21 18 0 44 -10 60 -21 l27 -22 36 36 c35 35 35 37 18 56 -39
43 -123 65 -192 50z m119 -32 c45 -20 62 -41 42 -57 -11 -9 -24 -8 -61 6 -45
17 -49 17 -96 0 -39 -14 -52 -15 -64 -5 -12 9 -12 15 -2 27 33 39 122 53 181
29z"/>
<path d="M5936 3119 c-31 -25 -34 -61 -6 -89 39 -39 110 -11 110 44 0 25 -41
66 -65 66 -7 0 -24 -9 -39 -21z m58 -15 c22 -8 20 -51 -2 -63 -25 -13 -52 5
-52 34 0 29 22 41 54 29z"/>
<path d="M4907 2783 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23 13z"/>
<path d="M5017 2772 c-9 -10 -17 -28 -17 -40 0 -13 -6 -22 -15 -22 -8 0 -15
-4 -15 -10 0 -5 7 -10 15 -10 12 0 15 -16 15 -85 0 -69 3 -85 15 -85 12 0 15
16 15 85 l0 85 30 0 c17 0 30 5 30 10 0 6 -14 10 -31 10 -27 0 -30 3 -27 28 3
23 7 27 36 27 40 0 37 19 -4 23 -20 2 -36 -4 -47 -16z"/>
<path d="M6787 2783 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23 13z"/>
<path d="M7087 2783 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23 13z"/>
<path d="M4416 2757 c-14 -14 -26 -34 -26 -44 0 -31 28 -61 63 -68 67 -14 99
-59 65 -93 -19 -19 -76 -14 -98 8 -18 18 -21 18 -26 5 -14 -36 74 -67 122 -43
69 32 52 112 -28 138 -57 18 -71 31 -66 61 4 30 42 40 85 23 18 -7 35 -9 38
-5 8 14 -22 33 -64 38 -33 5 -44 1 -65 -20z"/>
<path d="M5140 2730 c0 -11 -7 -20 -15 -20 -8 0 -15 -4 -15 -10 0 -5 7 -10 15
-10 12 0 15 -13 15 -59 0 -88 12 -111 55 -111 22 0 35 5 35 13 0 7 -12 13 -27
12 -28 0 -28 1 -31 73 l-3 72 31 0 c16 0 30 5 30 10 0 6 -13 10 -30 10 -23 0
-30 4 -30 20 0 11 -7 20 -15 20 -8 0 -15 -9 -15 -20z"/>
<path d="M6640 2730 c0 -11 -7 -20 -15 -20 -8 0 -15 -4 -15 -10 0 -5 7 -10 15
-10 12 0 15 -13 15 -59 0 -88 12 -111 55 -111 22 0 35 5 35 13 0 7 -12 13 -27
12 -28 0 -28 1 -31 73 l-3 72 31 0 c16 0 30 5 30 10 0 6 -13 10 -30 10 -23 0
-30 4 -30 20 0 11 -7 20 -15 20 -8 0 -15 -9 -15 -20z"/>
<path d="M7180 2730 c0 -11 -7 -20 -15 -20 -8 0 -15 -4 -15 -10 0 -5 7 -10 15
-10 12 0 15 -13 15 -59 0 -88 12 -111 55 -111 22 0 35 5 35 13 0 7 -12 13 -27
12 -28 0 -28 1 -31 73 l-3 72 31 0 c16 0 30 5 30 10 0 6 -13 10 -30 10 -23 0
-30 4 -30 20 0 11 -7 20 -15 20 -8 0 -15 -9 -15 -20z"/>
<path d="M4610 2700 c0 -6 10 -49 21 -96 28 -109 45 -110 74 -6 11 39 22 72
25 72 4 0 14 -32 25 -71 16 -63 35 -92 52 -76 6 6 42 154 43 175 0 6 -4 12 -9
12 -5 0 -17 -37 -28 -82 l-19 -83 -25 80 c-32 102 -47 104 -74 8 -11 -39 -22
-74 -25 -77 -3 -2 -13 31 -23 75 -10 43 -22 79 -28 79 -5 0 -9 -5 -9 -10z"/>
<path d="M4900 2615 c0 -78 3 -95 15 -95 12 0 15 17 15 95 0 78 -3 95 -15 95
-12 0 -15 -17 -15 -95z"/>
<path d="M5391 2689 c-11 -12 -23 -35 -26 -50 -8 -37 11 -96 35 -109 32 -17
85 -12 105 10 23 25 12 39 -13 17 -24 -22 -65 -22 -85 1 -23 26 -22 94 1 115
23 21 65 22 82 2 7 -8 17 -12 22 -9 5 3 2 14 -8 25 -24 27 -87 26 -113 -2z"/>
<path d="M5581 2691 c-40 -40 -34 -138 9 -161 24 -13 76 -13 99 0 45 24 51
120 10 161 -26 26 -92 26 -118 0z m101 -18 c23 -21 24 -89 1 -115 -20 -22 -61
-23 -85 -1 -23 21 -24 89 -1 115 20 22 61 23 85 1z"/>
<path d="M5790 2615 c0 -75 3 -95 14 -95 11 0 15 19 18 78 4 91 1 112 -18 112
-11 0 -14 -21 -14 -95z"/>
<path d="M5843 2704 c-27 -12 -11 -22 23 -17 47 8 54 -4 54 -94 0 -58 3 -73
15 -73 12 0 15 14 15 68 0 88 -15 116 -60 119 -19 1 -41 0 -47 -3z"/>
<path d="M6010 2615 c0 -75 3 -95 14 -95 11 0 15 19 18 78 4 91 1 112 -18 112
-11 0 -14 -21 -14 -95z"/>
<path d="M6063 2704 c-27 -12 -11 -22 23 -17 47 8 54 -4 54 -94 0 -58 3 -73
15 -73 12 0 15 14 15 68 0 88 -15 116 -60 119 -19 1 -41 0 -47 -3z"/>
<path d="M6258 2700 c-43 -26 -47 -136 -5 -165 41 -29 143 -9 123 24 -5 8 -11
7 -22 -4 -33 -33 -104 -6 -104 38 0 14 11 17 71 17 l72 0 -7 33 c-3 17 -16 40
-27 50 -23 19 -74 22 -101 7z m90 -22 c7 -7 12 -20 12 -30 0 -15 -9 -18 -55
-18 -41 0 -55 4 -55 14 0 38 70 62 98 34z"/>
<path d="M6451 2689 c-11 -12 -23 -35 -26 -50 -8 -37 11 -96 35 -109 32 -17
85 -12 105 10 23 25 12 39 -13 17 -24 -22 -65 -22 -85 1 -23 26 -22 94 1 115
23 21 65 22 82 2 7 -8 17 -12 22 -9 5 3 2 14 -8 25 -24 27 -87 26 -113 -2z"/>
<path d="M6780 2615 c0 -78 3 -95 15 -95 12 0 15 17 15 95 0 78 -3 95 -15 95
-12 0 -15 -17 -15 -95z"/>
<path d="M6888 2615 c48 -125 61 -125 108 0 39 105 37 95 21 95 -7 0 -25 -36
-41 -81 -16 -44 -32 -77 -36 -72 -4 4 -20 41 -35 81 -14 39 -33 72 -40 72 -9
0 -2 -28 23 -95z"/>
<path d="M7080 2615 c0 -78 3 -95 15 -95 12 0 15 17 15 95 0 78 -3 95 -15 95
-12 0 -15 -17 -15 -95z"/>
<path d="M7310 2700 c0 -5 16 -50 36 -100 35 -86 35 -91 20 -115 -10 -16 -26
-25 -41 -25 -14 0 -25 -4 -25 -10 0 -17 56 -11 73 8 9 10 27 47 41 82 13 36
33 89 45 118 15 37 17 52 8 52 -7 0 -25 -34 -40 -75 -16 -41 -31 -75 -35 -75
-4 0 -20 34 -35 75 -16 41 -32 75 -38 75 -5 0 -9 -4 -9 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
